<template>
  <ul class="docsify-item-ul">
    <li
      class="docsify-item-li"
      v-for="(item, index) in list"
      :key="item.id || index"
      :draggable="canDrag"
      @dragstart.stop="dragstart($event, { item })"
      @drag.stop="drag($event, { item })"
      @drop.stop="drop($event, { item, type: 'into' })"
      @dragover.stop="dragover($event, { item, type: 'into' })"
    >
      <div :style="{ marginLeft: `${hierarchy * 15}px`, fontWeight: hierarchy == 0 ? 700 : 400 }">
        <div class="docsify-item-group">
          <span
            class="docsify-item-title"
            :class="{
              'docsify-can-drag': canDrag,
              'docsify-item-active': targetDrag.type == 'into' && targetDrag.item.id == item.id,
            }"
            v-if="item.children"
          >
            <div class="docsify-title">
              {{ item.title }}
            </div>
          </span>
          <a
            href="#"
            class="docsify-item-title"
            :class="{
              'docsify-can-drag': canDrag,
              'docsify-item-active': targetDrag.type == 'into' && targetDrag.item.id == item.id,
            }"
            :draggable="canDrag"
            v-else
          >
            <div class="docsify-title-leaf">
              {{ item.title }}
            </div>
          </a>
          <div
            class="docsify-drop-line"
            :class="{ 'docsify-line-active': targetDrag.type == 'line' && targetDrag.item.id == item.id }"
          ></div>
          <!-- {{ targetDrag }} -->
        </div>
        <div
          class="docsify-drop-area"
          @drop.stop="drop($event, { item, type: 'line' })"
          @dragover.stop="dragover($event, { item, type: 'line' })"
        ></div>
      </div>
      <Item
        v-if="item.children"
        :list="item.children"
        :hierarchy="hierarchy + 1"
        :currentDrag="currentDrag"
        :targetDrag="targetDrag"
        :dragstart="dragstart"
        :drag="drag"
        :dragover="dragover"
        :drop="drop"
        :can-drag="canDrag"
      />
    </li>
  </ul>
</template>

<script>
import Item from './item'
export default {
  name: 'Item',
  components: {
    Item,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    hierarchy: {
      type: Number,
      default: () => 0,
    },
    canDrag: {
      type: Boolean,
      default: () => false,
    },
    currentDrag: {
      type: Object,
      default: () => ({
        type: null,
        item: null,
      }),
    },
    targetDrag: {
      type: Object,
      default: () => ({
        type: null,
        item: null,
      }),
    },
    dragstart: {
      type: Function,
      default: () => {},
    },
    drag: {
      type: Function,
      default: () => {},
    },
    drop: {
      type: Function,
      default: () => {},
    },
    dragover: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<style lang="stylus" scoped>
.docsify-item-ul {
  padding-left: 20px;
}

.docsify-item-li {
  list-style: none;
}

.docsify-can-drag:hover {
  cursor: move;
}

.docsify-item-group {
  width: fit-content;
}

.docsify-item-title {
  display: block;
  color: #505d6b;
  font-size: 14px;
  white-space: nowrap;
  padding: 0 2px;
}

.docsify-title-leaf:hover {
  text-decoration: underline;
}

.docsify-drop-area {
  height: 6px;
  width: 100%;
}

.docsify-drop-line {
  height: 2px;
  background: #fff;
}

.docsify-item-active {
  color: #fff;
  background: #5389df;
  border-radius: 10px;
}

.docsify-line-active {
  background: #558be0 !important;
}
</style>