var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "docsify-item-ul" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "li",
        {
          key: item.id || index,
          staticClass: "docsify-item-li",
          attrs: { draggable: _vm.canDrag },
          on: {
            dragstart: function ($event) {
              $event.stopPropagation()
              return _vm.dragstart($event, { item })
            },
            drag: function ($event) {
              $event.stopPropagation()
              return _vm.drag($event, { item })
            },
            drop: function ($event) {
              $event.stopPropagation()
              return _vm.drop($event, { item, type: "into" })
            },
            dragover: function ($event) {
              $event.stopPropagation()
              return _vm.dragover($event, { item, type: "into" })
            },
          },
        },
        [
          _c(
            "div",
            {
              style: {
                marginLeft: `${_vm.hierarchy * 15}px`,
                fontWeight: _vm.hierarchy == 0 ? 700 : 400,
              },
            },
            [
              _c("div", { staticClass: "docsify-item-group" }, [
                item.children
                  ? _c(
                      "span",
                      {
                        staticClass: "docsify-item-title",
                        class: {
                          "docsify-can-drag": _vm.canDrag,
                          "docsify-item-active":
                            _vm.targetDrag.type == "into" &&
                            _vm.targetDrag.item.id == item.id,
                        },
                      },
                      [
                        _c("div", { staticClass: "docsify-title" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "docsify-item-title",
                        class: {
                          "docsify-can-drag": _vm.canDrag,
                          "docsify-item-active":
                            _vm.targetDrag.type == "into" &&
                            _vm.targetDrag.item.id == item.id,
                        },
                        attrs: { href: "#", draggable: _vm.canDrag },
                      },
                      [
                        _c("div", { staticClass: "docsify-title-leaf" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.title) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                _c("div", {
                  staticClass: "docsify-drop-line",
                  class: {
                    "docsify-line-active":
                      _vm.targetDrag.type == "line" &&
                      _vm.targetDrag.item.id == item.id,
                  },
                }),
              ]),
              _c("div", {
                staticClass: "docsify-drop-area",
                on: {
                  drop: function ($event) {
                    $event.stopPropagation()
                    return _vm.drop($event, { item, type: "line" })
                  },
                  dragover: function ($event) {
                    $event.stopPropagation()
                    return _vm.dragover($event, { item, type: "line" })
                  },
                },
              }),
            ]
          ),
          item.children
            ? _c("Item", {
                attrs: {
                  list: item.children,
                  hierarchy: _vm.hierarchy + 1,
                  currentDrag: _vm.currentDrag,
                  targetDrag: _vm.targetDrag,
                  dragstart: _vm.dragstart,
                  drag: _vm.drag,
                  dragover: _vm.dragover,
                  drop: _vm.drop,
                  "can-drag": _vm.canDrag,
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }